import React, { useEffect, useRef, useState } from 'react';
import { ContainerServices } from "./style.js";

import { Link, useNavigate } from "react-router-dom";

import GlobalStyle from '../../globalStyles.js';
import Header from '../../components/Header/index.js'
import { Container, Row, Col, Nav, Navbar, NavLink } from "react-bootstrap";
import Footer from '../../components/Footer/index.js';
import service1 from "../../utils/imgs/svgs/service1.svg";
import service2 from "../../utils/imgs/svgs/service2.svg";

const Services = () => {
  return (
    <>
      <GlobalStyle />
      <ContainerServices>
        <Header />
        <Container className='services'>
          <h1>Explore Nossos Serviços Premium</h1>
          <Row className='service1'>
            <Col sm={6}>
              <Row>
                <Col sm={12} className='imgService'>
                  <img src={service1} />
                </Col>
                <Col sm={12}>
                  <h2>Criação de Sites Profissionais</h2>
                  <p>✓ Websites institucionais personalizados, lojas virtuais e soluções sob medida.</p>
                  <p>✓ Garanta destaque no Google com nosso serviço de otimização.</p>
                  <p>✓ Suporte dedicado 24/7 para sua tranquilidade.</p>
                  <p>✓ Equipe especializada para uma experiência do cliente impecável.</p>
                </Col>
                <Link to="https://api.whatsapp.com/send?phone=47920001822&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20sites%20desenvolvidos%20pela%20HLP%20Dev" className='more'>Quero saber mais!</Link>
              </Row>
            </Col>
            <Col sm={6} className='service2'>
              <Row>
                <Col sm={12} className='imgService'>
                  <img src={service2} />
                </Col>
                <Col sm={12}>
                  <h2>Delivery Go</h2>
                  <p>✓ Transforme seu negócio com nosso sistema de Delivery Web avançado.</p>
                  <p>✓ Aceite pagamentos online com total segurança.</p>
                  <p>✓ Gerencie seu estoque de maneira eficiente.</p>
                  <p>✓ Integramos perfeitamente ao seu sistema ERP, sem complicações.</p>
                </Col>
                <Link to="https://api.whatsapp.com/send?phone=47920001822&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20o%20Delivery%20Go" className='more'>Quero saber mais!</Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </ContainerServices>
      <Footer />
    </>
  );
}

export default Services;
