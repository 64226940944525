import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import FAQ from './pages/FAQ';
import Services from './pages/Services';


function RoutesController() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Home/> }/>
        <Route path='/duvidas' element={ <FAQ/> }/>
        <Route path='/servicos' element={ <Services/> }/>
        <Route path='*' element={ <Home/> }/>
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesController;
