import React, { useEffect, useContext, useState } from 'react';
import { ContainerHome } from "./style.js";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import GlobalStyle from './../../globalStyles';
import Header from '../../components/Header'
import { Container, Row, Col, Nav, Navbar, NavLink } from "react-bootstrap";
import Footer from '../../components/Footer';

import datahome from "../../utils/imgs/svgs/data-home.svg";
import cheffHome from "../../utils/imgs/cheff-home.jpg";

import 'react-toastify/dist/ReactToastify.css';

import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import { AiFillEye } from "react-icons/ai";
import { TbMapSearch } from "react-icons/tb";
import { BsFillRocketTakeoffFill } from "react-icons/bs";


const Home = () => {

  const [restartTyping, setRestartTyping] = useState(false);

  useEffect(() => {
    if (!restartTyping) {
      setRestartTyping(true);
    }
  }, [restartTyping]);

  const settingsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };


  return (
    <>
      <GlobalStyle />
      <ContainerHome>
        <Header />
        <Container className='FirstaboutUs'>
          <Row>
            <Col sm={5}>
              <img src={datahome} />
            </Col>
            <Col sm={7}>
              <h2>Traga seu estabelecimento<br /> ao mundo digital!</h2>
              <p>Destrua as barreiras que impedem o crescimento do seu estabelecimento no
                mundo digital, através de nossos serviços!</p>
            </Col>
          </Row>
        </Container>
        <svg id='SecondaboutUsScroll' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='SecondaboutUsScroll'>
          <path fill="#fff" fillOpacity="1" d="M0,160L80,170.7C160,181,320,203,480,181.3C640,160,800,96,960,74.7C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        </svg>
        <div className='SecondaboutUs'>
          <Container>
            <Row>
              <Col sm={7}>
                <div style={{ height: '50px' }}>
                  {restartTyping && (
                    <Typist
                      cursor={{ show: true, blink: true }}
                      avgTypingDelay={100}
                      stdTypingDelay={0}
                      startDelay={1000}
                      onTypingDone={() => setRestartTyping(false)}
                    >
                      <span>Muito prazer!</span>
                      <Typist.Delay ms={1000} />
                      <Typist.Backspace count={15} delay={500} />
                      <Typist.Delay ms={1000} />
                      <span>Nós somos a JPA Sistemas!</span>
                      <Typist.Delay ms={1000} />
                      <Typist.Backspace count={20} delay={500} />
                    </Typist>
                  )}
                </div>
                <p>Somos uma equipe apaixonada por gastronomia
                  e tecnologia, movidos pela missão de capacitar estabelecimentos alimentícios a prosperarem
                  no ambiente digital.</p>
              </Col>

              <Col sm={5}>
                <img src={cheffHome} />
              </Col>
              <Col sm={12} className='differentials'>
                <h1>Nossos Diferencias</h1>
                <div className='cardHome'>
                  <div className="card">
                    <h2>Experiência Transformadora</h2>
                    <p>Especialistas experientes focados em estabelecimentos alimentícios.</p>
                    <p>Profundo entendimento dos desafios e oportunidades digitais.</p>
                  </div>
                  <div className="card">
                    <h2>Espaço Virtual que Encanta</h2>
                    <p>Design atraente e navegação intuitiva.</p>
                    <p>Apresentação impactante e memorável de sua marca.</p>
                  </div>
                  <div className="card">
                    <h2>Tecnologia de Ponta</h2>
                    <p>Tecnologia simplificada para operações eficientes.</p>
                    <p>Gestão de pedidos, pagamentos online e rastreamento de entregas.</p>
                  </div>
                  <div className="card">
                    <h2>Apoio e Suporte Contínuo</h2>
                    <p>Mais do que uma plataforma: uma parceria.</p>
                    <p>Equipe de suporte pronta para treinamentos, dicas de marketing e soluções rápidas.</p>
                  </div>
                  <div className="card">
                    <h2>Credibilidade e Confiança</h2>
                    <p>Marca confiável com vasto histórico de sucesso.</p>
                    <p>Paixão e qualidade em todos os atendimentos.</p>
                  </div>
                  <div className="card">
                    <h2>Transformação ao Seu Alcance</h2>
                    <p>Supere barreiras tecnológicas e alcance novos patamares de sucesso.</p>
                    <p>Proporcione uma experiência digital única para seus clientes.</p>
                  </div>
                  <br/>
                  <a href="https://wa.link/73gc67">Junte-se à JPA Sistemas e revolucione sua presença no mundo digital!</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#fff" fillOpacity="1" d="M0,192L80,170.7C160,149,320,107,480,112C640,117,800,171,960,181.3C1120,192,1280,160,1360,144L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
        </svg>
        <div className='ThirdaboutUs'>
          <Container>
            <h1>A Importância de um Bom Espaço Virtual!</h1>
            <div className='cardHome'>
              <div className="card">
                <AiFillEye />
                <h2>Alcance e Visibilidade</h2>
                <p>Acesso sem fronteiras</p>
                <p>Visibilidade global</p>
                <p>Conquista de novos mercados</p>
              </div>
              <div className="card">
                <TbMapSearch />
                <h2>Jornada Aprimorada</h2>
                <p>Navegação fluída</p>
                <p>Transações fáceis</p>
                <p>Suporte constante</p>
              </div>
              <div className="card">
                <BsFillRocketTakeoffFill />
                <h2>Marca de Peso</h2>
                <p>Destaque-se na concorrência</p>
                <p>Reputação sólida</p>
                <p>Relações duradouras</p>
              </div>
            </div>
          </Container>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='fourthaboutUsScroll'>
          <path fill="#fff" fillOpacity="1" d="M0,224L80,197.3C160,171,320,117,480,133.3C640,149,800,235,960,245.3C1120,256,1280,192,1360,160L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        </svg>
        <div className='fourthaboutUs'>
          <Container>
            <h1>Conheça Nossos Parceiros!</h1>
            <Col sm={12}>
              <Slider {...settingsSlider}>
                {/* <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div>
                <div>
                  <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/2560px-Coca-Cola_logo.svg.png'} />
                </div> */}
              </Slider>
            </Col>
          </Container>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#fff" fillOpacity="1" d="M0,288L80,261.3C160,235,320,181,480,170.7C640,160,800,192,960,208C1120,224,1280,224,1360,224L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
        </svg>
      </ContainerHome >
      <Footer />
    </>
  );
}


export default Home;
