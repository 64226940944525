import React, { useEffect, useRef, useState } from 'react';
import { ContainerFAQ } from "./style.js";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GlobalStyle from './../../globalStyles';
import Header from '../../components/Header'
import { Container, Row, Col, Nav, Navbar, NavLink } from "react-bootstrap";
import Footer from '../../components/Footer';
import faqImg from "../../utils/imgs/svgs/faq.svg";

const FAQ = () => {


  return (
    <>
      <GlobalStyle />
      <ContainerFAQ>
        <Header />
        <Container className='faq'>
          <Row>
            <h1>Dúvidas Frequentes</h1>
            <Col sm={5}>
            <img src={faqImg}/>
            </Col>
            <Col sm={7}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Como a JPA Sistemas pode me ajudar a expandir meu estabelecimento para o ambiente digital?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ao se juntar à JPA Sistemas, você terá acesso a um espaço virtual personalizado, projetado
                    para atrair e conquistar clientes online. Nossa plataforma oferece um website intuitivo e
                    atraente, opções de pagamento online, rastreamento de pedidos e outras funcionalidades
                    avançadas para tornar sua operação online mais eficiente.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Preciso ser um especialista em tecnologia para usar o sistema?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Absolutamente não! O nosso sistema foi desenvolvido pensando na facilidade de uso. Nossa
                    equipe está pronta para oferecer treinamento e suporte contínuo para garantir que você se
                    sinta confortável ao usar nossa plataforma. Você pode focar no que faz de melhor - a qualidade
                    de seus produtos e o atendimento aos clientes - enquanto cuidamos do ambiente digital para
                    você.

                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Qual é a vantagem de ter um espaço digital para o meu estabelecimento?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ter um espaço digital é uma oportunidade única de alcançar um público maior e impulsionar
                    suas vendas. Com o mercado digital em constante crescimento, a presença online é
                    fundamental para se destacar da concorrência e atender às expectativas dos consumidores
                    modernos. Além disso, você terá acesso a dados valiosos sobre o comportamento do cliente,
                    permitindo tomar decisões estratégicas e informadas para o seu negócio.

                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography>Como posso acompanhar os pedidos dos meus clientes?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Você terá acesso a um painel de controle exclusivo, onde poderá gerenciar e acompanhar
                    todos os pedidos recebidos. Além disso, oferecemos notificações em tempo real para que você
                    esteja sempre atualizado sobre as atividades do seu estabelecimento.

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography>O sistema é seguro para o meu negócio?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Sim, a segurança é uma de nossas principais prioridades. Nossa plataforma utiliza tecnologias
                    avançadas de proteção de dados e criptografia para garantir a segurança das informações do
                    seu estabelecimento e de seus clientes.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography>Como posso começar a usar o sistema para o meu estabelecimento?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Para começar a usar o sistema e expandir seu estabelecimento para o mundo digital, basta
                    entrar em contato conosco através do nosso site ou telefone. Nossa equipe terá o prazer de
                    agendar uma demonstração personalizada e orientá-lo em todos os passos necessários para
                    começar a utilizar nossa plataforma
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </ContainerFAQ >
      <Footer />
    </>
  );
}


export default FAQ;
