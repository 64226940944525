import styled from "styled-components"

export const ContainerHeader = styled.div`
    margin: 20px 0px 20px 0px;
    
    .navbar {
        background: none;

        a {
            transition: 0.3s;
            color: #fff;
            font-size: 18px;
            margin: 10px 30px 10px 30px;
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
        }

        a:hover{
            transition: 0.5s;
            color: #fff;
            scale: 1.1;
        }
        
        img {
            width: 60px;
        }
        
        #navbarScroll{
            display: flex;
            justify-content: space-between;
        }

        button span{
            color: #fff;
        }

        @media (max-width: 600px) {
           img {
                display: none;
           }

           .collapse:not(.hide) {
                display: block !important;
            }

           .collapse:not(.show) {
                display: none !important;
            }
          }

          button {
            background: #fff;
          }
    }

`;



