import styled from "styled-components"

export const ContainerServices = styled.div`
    
margin-bottom: 150px;

        .services {

            h1 {
                margin-top: 50px;
                text-align: center;
                margin-bottom: 100px;
            }

            h2 {
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            p {
                margin-top: 10px;
            }
            
            .imgService {
                margin-bottom: 30px;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 200px;
                }
            }

            .service1{
                animation: service1-animation 1.5s ease normal;
            }
            
            .service2{
                animation: service2-animation 1.5s ease normal;
            }
        }

        .more{
            transition: 0.5s;
            text-align: center;
            margin-top: 30px;
            color: #fff;
            text-decoration: none;
            font-size: 18px;
        }

        .more:hover{
            transition: 0.5s;
            color: #fff;
            scale: 1.1;
        }

        @keyframes service1-animation {
            0% {
                opacity: 0;
                transform: translateY(-50px);
            }
        
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    
        @keyframes service2-animation {
            0% {
                opacity: 0;
                transform: translateY(-50px);
            }
            
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
        

        @media (max-width: 800px) {
            .services h1 {
                margin-bottom: 30px;
            }
            .services .imgService{
                margin-top: 70px;
            }
        }
`;