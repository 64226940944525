import React from 'react';
import { FooterContainer, WppContact } from './style.js';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaWhatsapp } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import wppIcon from "../../utils/imgs/whatsapp.png";

const Footer = () => {
  // Obtendo o ano atual
  const currentYear = new Date().getFullYear();

  return (
    <>
      <FooterContainer>
        <Container>
          <Row>
            <Col sm={6}>
              <h5>Contato</h5>
              <p>
                <FaWhatsapp /> (47) 99154-6652{' '}
              </p>
              <br />
            </Col>
            <Col sm={6}>
              <h5>Redes Sociais</h5>
              {/* <FaInstagram />deliveryGO{' '} */}
              <br />
            </Col>
            <Col sm={12}>
            <br />
              <p>&copy; {currentYear} Delivery Go. Todos os direitos reservados.</p>
            </Col>
          </Row>
        </Container>
        <WppContact>
          <Link to="https://wa.link/73gc67">
            <img src={wppIcon}/>
          </Link>
        </WppContact>
      </FooterContainer>
    </>
  );
};

export default Footer;
