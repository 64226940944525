import styled from "styled-components"

export const FooterContainer = styled.div`
  margin-top: 50px;
  min-height: 150px;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;

  @media (max-width: 800px) {
    .col-sm-12 {
      margin-top: 20px;
        font-size: 12px;
    }
}
`;

export const WppContact = styled.div`
  width: 70px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  animation: pulse 3s infinite;
  border-radius: 50%;

  img {
    width: 100%;
  }

  @keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 177, 0, 0.7);
    }
    50% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 177, 0, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 177, 0, 0);
    }
}

`;

