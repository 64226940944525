import React from "react";
import { ContainerHeader } from "./style.js";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

import logo from "../../utils/imgs/logo.png";
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Nav, Navbar } from "react-bootstrap";

const Header = ({ bg, ...rest }) => {
    const location = useLocation();  // Hook para pegar a localização atual

    return (
        <>
            <ContainerHeader {...rest}>
                <Container>
                    <Navbar collapseOnSelect expand="sm">
                        <Navbar.Toggle className="custom-toggler" aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Link to="/">
                                <img src={logo} alt="Logo" />
                            </Link>
                            <Nav>
                                <Link to="/">Home</Link>
                                {
                                    location.pathname === "/" ? (
                                        <>
                                            <ScrollLink to="SecondaboutUs" smooth={false} duration={300}>Sobre Nós</ScrollLink>
                                            <ScrollLink to="fourthaboutUsScroll" smooth={false} duration={300}>Parceiros</ScrollLink>
                                        </>
                                    ) : (
                                        <>
                                            <Link to="/">Sobre Nós</Link>
                                            <Link to="/">Parceiros</Link>
                                        </>
                                    )
                                }
                                <Link to="/servicos">Serviços</Link>
                                <Link to="/duvidas">Dúvidas</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </ContainerHeader>
        </>
    );
}

export default Header;
