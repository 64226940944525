import RoutesController from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RoutesController/>
      </div>
    </ThemeProvider>
  );
}

export default App;
