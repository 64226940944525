import styled from "styled-components"

export const ContainerFAQ = styled.div`
    
    .faq  {
        margin-bottom: 150px;
        
        h1 {
            margin-top: 50px;
            text-align: center;
            margin-bottom: 100px;
        }
    }

    .MuiPaper-root{
        margin: 20px 0px 20px 0px;
        border-radius: 10px !important;
        
        .MuiAccordionSummary-content p {
            font-weight: 600;
        }
    }
    
    p {
        color #551aa6;
    }

    .col-sm-7 {
        animation: faq-animation 1.5s ease normal;
    }

    .faq img {
        animation: img-animation 1.5s ease normal;
        width: 100%;
        padding: 15px;
    }

    @keyframes img-animation {
        0% {
            opacity: 0;
            transform: translateX(-50px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes faq-animation {
        0% {
            opacity: 0;
            transform: translateX(50px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @media (max-width: 800px) {
        .faq h1 {
            margin: 20px 0px 30px 0px;
        }
    }

`;