// globalStyles.js
import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #A0A0A0 #E6E6E6;
  }

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #E6E6E6;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #E6E6E6;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #E6E6E6;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: #A0A0A0;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #A0A0A0;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #8F8F8F;
  }

  body {
    margin: 0;
    padding: 0;
    background: #e6e6e6;
    font-family: "Lato",sans-serif;
    background-image: linear-gradient(to right bottom, #5912b5, #5619a8, #531e9b, #50228e, #4d2581) !important;
    background: no-repeat;
  }

  h1,h2,h3,h4,h5 {
    color: #fff;
  }

  p{
    color: #fff;
    margin: 0px;
  }
`;
 
export default GlobalStyle;