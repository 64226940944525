import styled from "styled-components"

export const ContainerHome = styled.div`
  

.FirstaboutUs {
    height: 80vh;
    margin-top: 80px;
    display: flex;
    align-items: center;

    div div:nth-child(1){
        text-align: center;
        
        img {
            width: 80%;
            animation: slide-left 1.5s ease-in-out;
        }
    }

    h2 {
        text-align: left;
        font-size: 50px;
        margin-bottom: 50px;
        animation: title-animation 1.5s ease normal;
    }

    p {
        font-size: 25px;
        animation: text-animate 1.5s ease normal none;
    }

    @keyframes slide-left {
        0% {
            opacity: 0;
            transform: translateX(-250px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }    
    
    @keyframes title-animation {
        0% {
            opacity: 0;
            transform: translateX(50px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes text-animate {
        0% {
            opacity: 0;
            transform: translateY(-50px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.SecondaboutUs {
    background: #fff;
    padding: 0px 0px 50px 0px;
    margin-top: -5px;

    display: flex;
    align-items: center;

    .typist {
        min-height: 20px;
    }

    .row {
        display: flex;
        align-items: center;

        div:nth-child(0){
            padding: 30px;
        }

        img {
            width: 100%;
            border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
            animation: morph 10s linear infinite;
        }
    }

    .Typist , p {
        color: #512093 !important;
    }

    .Typist {
        font-size: 2vw;
    }

    p {
        margin-top: 30px;
        font-size: 1.3vw;
    }

    .differentials{
        h1 {
            text-align: center;
            margin-top: 100px;
            margin-bottom: 20px;
            color: #512093;
        }

        .cardHome {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: top;
            justify-content: center;
    
            .card {
                width: 30%;
                padding: 20px;
                margin: 10px;
                box-shadow: 0px 0px 20px  #cccccce3;
                border: none;
                
                h2, p {
                    color: #512093;
                }

                h2 {
                    font-size: 20px;
                    height: 40px;
                    font-weight: bold;
                    text-align: center;
                }

                p {
                    font-size: 18px;
                }
    
                border-radius: 15px;

            }
            
            svg {
                margin: 0 auto;
                font-size: 2.5vw;
                margin-bottom: 30px;
                color: #521e99;
            }

            a {
                text-decoration: none;
                margin-top: 30px;
                font-size: 25px;
                color: #512093;
                font-style: italic;
            }
        }
    }

    @keyframes morph{
        0%, 100% {
        border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
        }
        34% {
          border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        }
        67% {
          border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        }
      }
}

.ThirdaboutUs{
    padding-bottom: 40px;
    margin-top: -5px;

    h1 {
        font-size: 35px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 100px;
    }

    h2 {
        font-size: 22px;
        text-align: center;
        font-weight: 600;
    }

    p {
        text-align: center;
        font-size: 17px;
        margin-top: 20px;
    }

    .cardHome {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .card {
            width: 30%;
            padding: 20px;
            h2, p {
                color: #512093;
            }

            border-radius: 15px;
        }

        svg {
            margin: 0 auto;
            font-size: 2.5vw;
            margin-bottom: 30px;
            color: #521e99;
        }
    }
}

.fourthaboutUs {
    background: #fff;
    padding: 0px 0px 50px 0px;  
    margin-top: -5px;

    h1, p {
        color: #512093 !important;
    }

    h1 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 100px;
    }

    .slick-slide {
        display: inline-block;
    }
    
    img {
        width: 100%;
        height: auto;  
        padding: 20px;
    }
}


@media (max-width: 800px) {
    .FirstaboutUs{
        margin-top: 100px;
        padding-bottom: 100px;

        h2 {
            margin-top: 20px;
            font-size: 30px;
        }

        p {
            font-size: 20px;
        }
    }

    .SecondaboutUs { 
        margin: -1px 0px -1px 0px;

        span {
            font-size: 30px;
        }

        p {
            font-size: 20px;
            margin: 50px 0px 50px 0px;
        }

        .cardHome {
            display: block !important;

            .card {
                width: 100% !important;
                margin: 0px 0px 10px 0px !important;

                h2 {
                    font-size: 18px !important;
                }

                p{
                    font-size: 16px !important;
                    margin: 10px 0px 10px 0px !important;
                }
            }

            a {
                padding-top: 40px !important;
                font-size: 20px !important;
            }

        }
    }

    .ThirdaboutUs {
        padding-top: 50px;

        h1 {
            font-size: 25px;
        }

        .cardHome {
            display: block !important;
            .card {
                width: 100% !important;
                margin-bottom: 20px;
            }
        }
            
        svg{
            font-size: 50px !important;
        }    
    }

    .fourthaboutUs {
        padding-top: 50px;

        button {
            display: none !important; 
        }

         h1 {
            font-size: 25px;
            margin-bottom: 50px;
         }
    }


  }


  
`;